import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate, redirect } from 'react-router-dom';
import LoginPage from './pages/Login/LoginPage';
import Main from './pages/Main/Main';

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>загрука..</div>}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<Main />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
