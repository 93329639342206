import React from 'react';
import cl from './MainWrapper.module.scss';

type Props = {
    children: React.ReactNode
}

const MainWrapper: React.FC<Props> = ({children}) => (
    <div className={cl.content}>
        {children}
    </div>
)

export default MainWrapper;