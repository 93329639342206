import React, { ChangeEvent, useEffect, useState } from 'react';
import cl from './LoginPage.module.scss';
import MainWrapper from '../../wrappers/MainWrapper';
import logo from '../../assets/logo.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import loading from '../../assets/loading.gif';


const LoginPage = () => {

    const [form, setForm] = useState({login: '', password: ''});

    const [error, setError] = useState('');
    const [isFetching, setFetching] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value
        setForm((state) => ({
            ...state, [name]: value
        }))
        setError('');
    }

    const handleClick = () => {
        setFetching(true);
        axios.post('https://polyclinika-api.it-cron.ru/api/v1/auth/login', form).then((res) => {
            if (res?.data?.data?.accessToken){
                sessionStorage.setItem('accessToken', res.data.data.accessToken);
                setFetching(false);
                navigate('/');    
            }
        })
        .catch((e) => {
            setFetching(false);
            setError(e?.response?.data?.error?.message || 'Ошибка')
        })
    }

    const token = sessionStorage.getItem('accessToken');
    useEffect(() => {
        if (token){
            navigate('/')
        }
    }, [navigate, token])

    return (
        <MainWrapper>
            <div className={cl.content}>
                <div>
                    <div className={cl.logo}>
                        <img src={logo} />
                    </div>
                    <div className={cl.form}>
                        <div>
                            <div className={cl.label}>Логин</div>
                            <input placeholder='Введите логин' onChange={handleChange} value={form.login} name='login' className={cl.input} />
                        </div>
                        <div>
                            <div className={cl.label}>Пароль</div>
                            <input placeholder='Введите пароль' onChange={handleChange} value={form.password} name='password' type='password' className={cl.input} />
                            <div className={cl.error}>{error}</div>
                        </div>
                        <div className={cl.forget}>Забыли пароль?</div>
                    </div>
                </div>
                <div className={cl.buttons}>
                    <button onClick={handleClick} className={cl.enter}>{isFetching ? <img height={'50px'} src={loading} /> : 'Войти'}</button>
                    <button className={cl.registration}>Регистрация</button>
                    <div className={cl.conf}>Политика конфиденциальности</div>
                </div>
            </div>
        </MainWrapper>
    )
}


export default LoginPage;