import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainWrapper from "../../wrappers/MainWrapper";
import cl from './Main.module.scss';
import clinic from '../../assets/Clinic.png';
import heart from  '../../assets/Heart.png';
import home from '../../assets/Home.png';
import profile from '../../assets/Profile.png';
import document from '../../assets/Document.png';

import clinicFill from '../../assets/Clinic fill.png';
import heartFill from  '../../assets/Heart fill.png';
import homeFill from '../../assets/Home fill.png';
import profileFill from '../../assets/Profile fill.png';
import documentFill from '../../assets/Document fill.png';

const list = [
    {
        name: 'main',
        title: 'Главная',
        img: home,
        active: homeFill
    },
    {
        name: 'card',
        title: 'Медкарта',
        img: document,
        active: documentFill
    },
    {
        name: 'clinics',
        title: 'Клиники',
        img: clinic,
        active: clinicFill
    },
    {
        name: 'chemistry',
        title: 'Лекарства',
        img: heart,
        active: heartFill
    },
    {
        name: 'profile',
        title: 'Профиль',
        img: profile,
        active: profileFill
    },
]

const Main = () => {

    const [active, setActive] = useState('main');
    const token = sessionStorage.getItem('accessToken');
    const navigate = useNavigate();
    useEffect(() => {
        if (!token){
            navigate('/login')
        }
    }, [navigate, token])

    const handleClickExit = () => {
        sessionStorage.clear();
        navigate('/login')
    }
    return (
        <MainWrapper>
            <div className={cl.header}>{list.find(el => el.name === active)?.title.toUpperCase()}</div>
            <div className={cl.exit} onClick={handleClickExit}>Выйти</div>
            <div className={cl.toolbar}>
                {list.map((el) => (
                    <div key={el.name} onClick={() => setActive(el.name)} className={cl.item} style={active === el.name ? {color:'#F18D18'} : {color: '#767F8F'}}>
                        <img src={active === el.name ? el.active : el.img} />
                        {el.title}
                        </div>
                ))}
            </div>
        </MainWrapper>
    )
}

export default Main